<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
         <Dialog header="Mantenimientos programados" v-model:visible="mantenimientoModal" :style="{width: '50vw'}">

            <div> 
                
             <MantList :maquina="selectedMachine" />
            </div>

         </Dialog>
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <Button
                label="Nuevo"
                icon="pi pi-plus"
                class="p-button-success mr-2"
                @click="openNew"
              />
              
            </div>
          </template>

          <template v-slot:end>
            <Button
              label="Exportar"
              icon="pi pi-upload"
              class="p-button-help"
              @click="exportCSV($event)"
            />
          </template>
        </Toolbar>

        <DataTable
          ref="dt"
          :value="products"
          v-model:selection="selectedProducts"
          dataKey="id"
          :paginator="true"
          :rows="10"
          :filters="filters"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} maquinaria"
          responsiveLayout="scroll"
        >
          <template #header>
            <div
              class="
                flex flex-column
                md:flex-row md:justify-content-between md:align-items-center
              "
            >
              <h5 class="m-0">Maquinaria</h5>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters['global'].value"
                  placeholder="Buscar..."
                />
              </span>
            </div>
          </template>

          <Column
            field="name"
            header="Nombre"
            :sortable="true"
            headerStyle="width:20%; min-width:10rem;"
            bodyStyle="vertical-align: top;"
          >
            <template #body="slotProps" style="">

              <div class="" style="">
                {{ slotProps.data.name }}
                </div>
              <span class="p-column-title">Nombre</span>
              
            </template>
          </Column>

          <Column headerStyle="width: 70%">
          <template #body="slotProps">


            <MantBars :maquina="slotProps.data" />

            <!-- <div v-for="(mants, index) in mantenimientos[slotProps.data.id]" :key="index">
xxx
                {{ mants }}

            </div>
             -->
          <div>
            <!-- <div  class="mt-1">
            <b>REPARACIÓN </b>
             <ProgressBar   :value="randomNumber(1,100)" :class="giveMeClass(value)" >
               Faltan {{randomNumber(1,100)}} dias
             </ProgressBar>
            </div> -->

             </div>
          </template>
          </Column>
                    <Column headerStyle="min-width:10rem;">
            <template #body="slotProps">
              <Button
                icon="pi pi-cog"
                class="p-button-rounded p-button-info mt-2 mr-2"
                @click="verMantenimientos(slotProps.data)"
              />

              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success mr-2"
                @click="editProduct(slotProps.data)"
              />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-warning mt-2"
                @click="confirmDeleteProduct(slotProps.data)"
              />
            </template>
          </Column>
        </DataTable>

        <Dialog
          v-model:visible="productDialog"
          :style="{ width: '450px' }"
          header="Maquinaría"
          :modal="true"
          class="p-fluid"
        >

          <div class="field">
            <label for="name">Nombre </label>
            <InputText
              id="name"
              v-model.trim="product.name"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !product.name }"
            />
            <small class="p-invalid" v-if="submitted && !product.name"
              >Nombre es requerido.</small
            >
          </div>
          

          <template #footer>
            <Button
              label="Cancelar"
              icon="pi pi-times"
              class="p-button-text"
              @click="hideDialog"
            />
            <Button
              label="Guardar"
              icon="pi pi-check"
              class="p-button-text"
              @click="saveProduct"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="deleteProductDialog"
          :style="{ width: '450px' }"
          header="Confirmación"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product"
              >Eliminaras a <b>{{ product.name }} </b
              >?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductDialog = false"
            />
            <Button
              label="Sí"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteProduct"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="deleteProductsDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product">Seguro?</span>
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductsDialog = false"
            />
            <Button
              label="Sí"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteSelectedProducts"
            />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import CrudService from "../services/crud.service";
import eventService from "../services/maquinaria.service";
import MantBars from "../components/MantBars.vue";
import MantList from "../components/MantList.vue";

export default {
  data() {
    return {
      routeName: null,
      products: null,
      selectedMachine: null,
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      product: {},
      selectedProducts: null,
      filters: {},
      submitted: false,
      validationErrors: null,
      mantenimientoModal: false,
      mantenimientos: null,
      events: null,

    };
  },
  eventService: null,
  components: {
    MantBars,
    MantList
  },
  created() {
    // get route name
    this.routeName = this.$route.name;
  this.eventService = new eventService();
    this.initFilters();
  },
  mounted() {
     CrudService.getCRUD(this.routeName).then(
      (data) => (this.products = data)
    );



  },
  methods: {
    getMant(id){
      
      this.eventService.getMantenimientos(id).then(
        (data) => {
          console.log(data[0])
          
          //return data[0]
        }
      );
    
    },
    verMantenimientos(id){

      this.selectedMachine = id;

      this.mantenimientoModal = true

    },
    afipType(value) {
      if (value == 1) {
        return "Responsable Inscripto";
      }
      if (value == 2) {
        return "Monotributista";
      }
      if (value == 3) {
        return "Exento";
      }
      if (value == 4) {
        return "Consumidor Final";
      }

      return " - ";
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      return;
    },
    openNew() {
      this.product = {};
      this.submitted = false;
      this.productDialog = true;
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },
    displayErrors(errors) {
      
              for (let key in errors) {

                this.validationErrors.push({ "field": key, "message": errors[key] });

                let error = errors[key];
                this.$toast.add({
                  severity: "error",
                  summary: "Error!",
                  detail: error[0],
                  life: 3000,
                });
              }
    },
    saveProduct() {
      this.submitted = true;
      if (this.product.name.trim()) {
        if (this.product.id) {
          CrudService.updateCRUD(this.routeName, this.routeName, this.product).then(
            (data) => {

            this.validationErrors = [];
            if (data.status == 400) { 

              this.displayErrors(data.data);

            } else {
              this.$toast.add({
                severity: "success",
                summary: "Éxito!",
                detail: "Actualizado correctamente.",
                life: 3000,
              });
              this.products[this.findIndexById(this.product.id)] = this.product;
              this.productDialog = false;
              this.product = {};
            }
          });
        } else {
          CrudService.createCRUD(this.routeName, this.routeName, this.product).then(
            (data) => {

            this.validationErrors = null;
            if (data.status == 400) { 
              let errors = data[1];
              this.validationErrors = errors;
                this.$toast.add({
                  severity: "error",
                  summary: "Error!",
                  detail: "Algun campo es incorrecto o está vacío.",
                  life: 3000,
                });
              } else {
                this.products.push(data);
                this.$toast.add({
                  severity: "success",
                  summary: "Éxito!",
                  detail: "Creado correctamente.",
                  life: 3000,
                });
                this.productDialog = false;
                this.product = {};
              }
            }
          );
        }
      }
    },
    giveMeClass(machine) {
      console.log(machine)
        let num = this.randomNumber(1, 3);
        return "p-progressbar-"+num;
      
    },
    randomNumber(min,max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    
    },
    editProduct(product) {
      this.product = { ...product };
      this.productDialog = true;
    },
    confirmDeleteProduct(product) {
      this.product = product;
      this.deleteProductDialog = true;
    },
    deleteProduct() {
      
      CrudService.deleteCRUD(this.routeName, this.routeName, this.product.id).then(
        (data) => {

        

          this.$toast.add({
            severity: "success",
            summary: "Éxito!",
            detail: "Eliminado correctamente.",
            life: 3000,
          });


        }
      );

      this.products = this.products.filter((item) => item.id !== this.product.id);
      this.deleteProductDialog = false;

    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    deleteSelectedProducts() {
      this.products = this.products.filter(
        (val) => !this.selectedProducts.includes(val)
      );
      this.deleteProductsDialog = false;
      this.selectedProducts = null;
      this.$toast.add({
        severity: "success",
        summary: "Éxito!",
        detail: "Eliminado correctamente.",
        life: 3000,
      });
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/demo/badges.scss";
</style>
<style lang="scss" scoped>


::v-deep(.p-progressbar-1 ) {
    height: 20px;


      .p-progressbar-value{        
        background-color: #66BB6A;
      }

}

::v-deep(.p-progressbar-2 ) {
    height: 20px;


      .p-progressbar-value{        
        background-color: #FFA726 ;
      }

}

::v-deep(.p-progressbar-3 ) {
    height: 20px;


      .p-progressbar-value{        
        background-color: #EF5350;
      }

}

.titlemachine {
    width: 50px;
    height: 50px;
    background: #999;
    display: inline-block;
    vertical-align: top; /* here */
}

</style>
